<template>
    <div class="pa-4">
        <v-data-table
            :headers="_headers"
            :items="items"
            sort-by="code"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Stok Bahan Baku</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="initialize">
                  Refresh
                </v-btn>
                <v-btn color="primary" dark class="ml-2" @click="dialog=true" v-if="$store.state.currentPagePriv.create">
                  Add New
                </v-btn>
                <v-dialog v-model="dialog" max-width="800px">
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <span>Code</span>
                            <v-text-field maxlength="20" v-model="editedItem.code" filled rounded dense hide-details="auto" @keydown.space.prevent
                              :readonly="editedIndex>-1" :rules="reqRule"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Description</span>
                            <v-text-field v-model="editedItem.description" filled rounded dense hide-details="auto" :rules="reqRule"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <span>Satuan Ukuran</span>
                            <v-select filled rounded dense :items="uom" v-model="editedItem.uom" :rules="reqRule"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <span>Yield1 (Hasil Potongan)</span>
                            <v-text-field type="number" filled rounded dense v-model="editedItem.volume_perc" suffix="%" @wheel="$event.target.blur()"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <span>Yield2 (Hasil Juice)</span>
                            <v-text-field type="number" filled rounded dense v-model="editedItem.volume_perc2" suffix="%" @wheel="$event.target.blur()"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <span>Jumlah Stock</span>
                            <v-text-field v-model="editedItem.stock_quantity" filled rounded dense hide-details="auto" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="5">
                            <span>Category</span>
                            <v-select filled rounded dense :items="categories" item-text="category_name" item-value="code" v-model="editedItem.category_code" :rules="reqRule"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <span>Volume Label</span>
                            <v-select filled rounded dense :items="volBotol" item-text="description" item-value="code" v-model="editedItem.label_volume"></v-select>
                            <span>Hanya untuk item Label</span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <span>Satuan di Resep</span>
                            <v-select filled rounded dense :items="uom_resep" v-model="editedItem.uom_resep"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <span>Pembulatan di Resep</span>
                            <v-select filled rounded dense :items="rounding_resep" v-model="editedItem.rounding_resep"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <span>Persentase Biji</span>
                            <v-text-field type="number" filled rounded dense v-model="editedItem.seed_percentage" suffix="%" @wheel="$event.target.blur()"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" v-if="profile.role_code=='SUP'">
                            <span>Cost</span>
                            <v-text-field type="number" filled rounded dense v-model="editedItem.cost" prefix="Rp" @wheel="$event.target.blur()"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <span>Isi Lengkap</span>
                            <v-checkbox filled rounded dense v-model="editedItem.complete_fill"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Supplier</span>
                            <v-select filled rounded dense :items="suppliers" item-text="supplier_name" item-value="code" v-model="editedItem.supplier_code"></v-select>
                        </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="body-1 text-center">Anda akan menghapus bahan baku ini?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" v-if="$store.state.currentPagePriv.update">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" v-if="$store.state.currentPagePriv.delete">
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
            </template>
            <template v-slot:[`item.stock_quantity`]="{ item }">
              {{item.stock_quantity | rounded2}}
            </template>
            <template v-slot:[`item.category_code`]="{ item }">
              {{getCategoryName(item.category_code)}}
            </template>
            <template v-slot:[`item.supplier_code`]="{ item }">
              {{getSupplierName(item.supplier_code)}}
            </template>
            <template v-slot:[`item.hasil_potongan`]="{ item }">
              {{getHasilPotongan(item) | numbers}}
            </template>
            <template v-slot:[`item.hasil_juice`]="{ item }">
              {{getHasilJuice(item) | numbers}}
            </template>
            <template v-slot:[`item.cost`]="{ item }">
              {{Number(item.cost)  | numbers}}
            </template>
            <template v-slot:[`item.total_cost`]="{ item }">
              {{Number(item.stock_quantity)*Number(item.cost)  | numbers}}
            </template>
        </v-data-table>
    </div>
</template>

<script>

  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Description', value: 'description' },
        { text: 'Jumlah Stock', value: 'stock_quantity' },
        { text: 'UoM', value: 'uom' },
        { text: 'Yield1 (%)', value: 'volume_perc' },
        { text: 'Hasil Potongan (gr)', value: 'hasil_potongan', sortable: false },
        { text: 'Yield2 (%)', value: 'volume_perc2' },
        { text: 'Hasil Juice (ml)', value: 'hasil_juice', sortable: false },
        { text: 'Category', value: 'category_code' },
        { text: 'Cost (Rp)', value: 'cost' },
        { text: 'Total Cost (Rp)', value: 'total_cost', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        complete_fill: 1,
        stock_quantity: 0,
        cost: 0
      },
      defaultItem: {
        complete_fill: 1,
        stock_quantity: 0,
        cost: 0
      },
      search: '',
      suppliers: [],
      categories: [],
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
      uom_resep: ['kg', 'gr', 'ml'],
      rounding_resep: [-2, -1, 0, 1, 2]
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Bahan Baku' : 'Edit Bahan Baku'
      },
      roles () {
        return this.$store.state.role.objs
      },
      uom () {
        return this.$store.state.rawmaterial.uom
      },
      profile () {
        return this.$store.state.profile
      },
      _headers () {
        return this.headers.filter(x => 
          (x.value!='cost' && x.value!='total_cost') || this.profile.role_code=='SUP'
        )
      },
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      volBotol() {
        const pret = this.miscCodes.filter(element => {
          return element.type=='Botol'
        })
        pret.forEach(element => {
          element.code = Number(element.code)
        });
        pret.sort((a,b) => a.code - b.code); 
        pret.unshift({code:'', description:''})
        return pret
      }
    },

    filters: {
      numbers: function (number) {
          return Number(Math.round(number)).toLocaleString()
      },
      numbers2: function (number) {
          return Number(Math.round(number,2)).toLocaleString()
      }

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.suppliers = (await this.$store.dispatch('supplier/getObjs')).data.data
        this.categories = (await this.$store.dispatch('category/getObjs')).data.data
        let response = await this.$store.dispatch('rawmaterial/getObjs')
        if (response.data.status=='success') this.items = response.data.data
      },

      getHasilPotongan(item) {
        return (item.stock_quantity * (item.volume_perc/100)) * 1000
      },
      
      getHasilJuice(item) {
        return this.getHasilPotongan(item) * (item.volume_perc2/100)
      },
      
      getCategoryName(code) {
        return this.categories.find(element => {
          return element.code == code
        }).category_name;
      },

      getSupplierName(code) {
        if (!code) return;
        return this.suppliers.find(element => {
          return element.code == code
        }).supplier_name;
      },

      editItem (item) {
        console.log(item)
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        try {
          let url = this.$store.state.config.apiHost + "rawMaterials/" + this.editedItem.code
          let response = await this.$axios.delete(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') this.items.splice(this.editedIndex, 1)
          this.closeDelete()
        }
        catch (err) {
          const keyword = 'Foreign key'
          if (err.response.data.message.search(keyword) > -1){
            return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Bahan Baku tidak dapat dihapus, sedang digunakan!"})
          }
          else {
            return this.$store.commit('setSnackbar', {saveError: true, errorMessage: err.response.data.message})
          }
        }

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        await this.$refs.form.validate();
        if (!this.valid) return;

        if (this.editedIndex > -1) {
          console.log(this.editedItem)
          let url = this.$store.state.config.apiHost + "rawMaterials/" + this.editedItem.code
          let response = await this.$axios.put(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') Object.assign(this.items[this.editedIndex], this.editedItem)
          this.close()
          
        } else {
          try {
            let url = this.$store.state.config.apiHost + "rawMaterials"
            let response = await this.$axios.post(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
            if (response.data.status=='success') this.items.push(this.editedItem)
            this.close()
          }
          catch (err) {
            const keyword = 'been taken'
            if (err.response.data.message.search(keyword) > -1){
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Duplicate Key! Kode sudah terpakai."})
            }
            else {
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: err.response.data.message})
            }
          }
          
        }
      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>